import { translateText } from "../api/firebase";
import ticketManager from "./ticket";
import logManager from "./log";
import analyticsManager from "./analytics";

class AgentManager {
  translateAgentComment(ticket, agentLocale, customerLocale, commentContent) {
    return new Promise((resolve, reject) => {
      if (agentLocale === customerLocale) {
        reject({
          error: `Agent locale ${agentLocale} and customer comment locale ${customerLocale} are the same`
        });
        return;
      }

      const sanitizedCommentContent = ticketManager.sanitizeAgentComment(commentContent);
      logManager.log("Translating agent comment", sanitizedCommentContent);

      analyticsManager.logEvent("agent.comment.translation.completed", {
        agent_locale: agentLocale,
        customer_locale: customerLocale
      });

      const preferredProviders = ["google"];

      translateText({
        text: sanitizedCommentContent,
        source_locale: agentLocale,
        target_locale: customerLocale,
        providers: preferredProviders
      })
        .then(result => {
          logManager.log(result);
          if (result.data && result.data.length > 0) {
            const provider = result.data[0];
            ticketManager
              .addComment(ticket, provider.target.text, true)
              .then(resp => {
                resolve({
                  status: "Agent comment have been translated"
                });
              })
              .catch(err => {
                reject({
                  error: err
                });
              });
          } else {
            analyticsManager.logEvent('agent.comment.translation.failed', {
              agent_locale: agentLocale,
              customer_locale: customerLocale,
              via: preferredProviders
            });

            reject({
              error: "Failed to translate agent's comment"
            });
          }
        })
        .catch(error => {
          analyticsManager.logEvent('agent.comment.translation.failed', {
            agent_locale: agentLocale,
            customer_locale: customerLocale,
            via: preferredProviders
          });

          reject({
            error: error
          });
        });
    });
  }
}

const manager = new AgentManager();

export default manager;
