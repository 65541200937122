import { translateText } from "../api/firebase";
import ticketManager from "./ticket";
import { getLanguageName } from "../utils/language";
import logManager from "./log";
import analyticsManager from "./analytics";

class CustomerManager {
  translateCustomerComments(ticket, agentLocale, customerLocale, comments) {
    return new Promise((resolve, reject) => {
      const content = [];
      comments.forEach(function(comment) {
        content.push(ticketManager.sanitizeCustomerComment(comment.value));
      });

      const text = content.join("\n\n");
      logManager.log("Translating customer comments", text);

      const preferredProviders = ["google", "bing"];

      translateText({
        text: text,
        source_locale: customerLocale,
        target_locale: agentLocale,
        providers: preferredProviders
      })
        .then(result => {

          if (result.data && result.data.length > 0) {
            let privateComment = `Here are the translations of the customer's last comment(s) from **${getLanguageName(
              customerLocale
            )}** to **${getLanguageName(agentLocale)}**`;

            const providers = [];
            result.data.forEach(function(provider) {
              providers.push(provider.via);
              privateComment += `\n\nvia **${provider.via}**:\n\n${provider.target.text}\n\n\n---`;
            });

            analyticsManager.logEvent('customer.comment.translation.completed', {
              customer_locale: customerLocale,
              agent_locale: agentLocale,
              via: providers
            });

            ticketManager
              .addComment(ticket, privateComment, false)
              .then(resp => {
                resolve({
                  status: "Customer comment(s) have been translated"
                });
              })
              .catch(err => {
                reject({
                  error: err
                });
              });

            } else {
              analyticsManager.logEvent('customer.comment.translation.failed', {
                customer_locale: customerLocale,
                agent_locale: agentLocale,
                via: preferredProviders
              });

              reject({
                error: "Failed to translate customer comments"
              });
            }
        })
        .catch(error => {
          analyticsManager.logEvent('customer.comment.translation.failed', {
            customer_locale: customerLocale,
            agent_locale: agentLocale,
            via: preferredProviders
          });

          reject({
            error: error
          });
        });
    });
  }
}

const manager = new CustomerManager();

export default manager;
