import logManager from "./log";
const mixpanel = require("mixpanel-browser");

const MIXPANEL_API_KEY = "70fa5b5d9d5ff0f4e624a47c45a2baec";

class AnalyticsManager {
  constructor() {
    mixpanel.init(MIXPANEL_API_KEY);
  }

  logEvent(event, params) {
    logManager.log(`Log analytics event`, event, params);
    mixpanel.track(event, params);
  }
}

const manager = new AnalyticsManager();

export default manager;
