const { getLangNameFromCode } = require("language-name-map");

export const getLanguageName = code => {
  const lang = getLangNameFromCode(code);
  if (lang) return lang.name;
  return code;
};

export const sanitizeLocale = locale => {
  if (!locale) return null;
  return locale.split("-")[0].toLowerCase();
};

export const isSameLanguage = (customerLocale, agentLocale) => {
  return (
    customerLocale !== null &&
    sanitizeLocale(customerLocale) === sanitizeLocale(agentLocale)
  );
};
