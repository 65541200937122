import agentManager from "./agent";
import { zafClient } from "../api/zaf";
import { GLOBALIZE_TRANSLATE_TAG } from "../utils/constants";
import { isSameLanguage, sanitizeLocale } from "../utils/language";
import logManager from "./log";
import analyticsManager from "./analytics";

class ZafManager {

  getUser() {
    return new Promise((success, failure) => {
      zafClient
        .request({
          url: "/api/v2/users/me.json",
          type: "GET",
          contentType: "application/json"
        })
        .then(response => {
          const user = response.user;

          analyticsManager.logEvent("agent.loaded", {
            email: user.email,
            agent_locale: user.locale
          });

          success(user);
        })
        .catch(error => {
          logManager.error("Failed to load agent information", error);
          failure(error.message);
        });
    });
  }

  getTicket() {
    return new Promise((success, failure) => {
      zafClient
        .get("ticket")
        .then(data => {
          const currentTicket = data.ticket;

          analyticsManager.logEvent("ticket.loaded", {
            ticket_id: currentTicket.id
          });

          success(currentTicket);
        })
        .catch(error => {
          logManager.error("Failed to load ticket", error);
          failure(error.message);
        });
    });
  }

  initZafEvents(ticket, agent, customerLocale) {
    let inInternalNote = false;
    let internalNoteContent = null;

    zafClient.on("app.registered", context => {
      logManager.log("app.registered", context);
    });

    zafClient.on("ticket.updated", event => {
      logManager.log("ticket.updated", event);
      if (event.role === "end-user") {
        window.setTimeout(function() {
          // processComments(agent, ticket, locale);
        }, 1000);
      }
    });

    zafClient.on("comment.text.changed", content => {
      logManager.log("comment.text.changed", content);
      internalNoteContent = content;
    });

    zafClient.on("comment.type.changed", type => {
      logManager.log("comment.text.changed", type);
      inInternalNote = type === "internalNote";
    });

    zafClient.on("ticket.submit.start", function() {
      logManager.log(
        "ticket.submit.start",
        ticket,
        agent,
        inInternalNote,
        customerLocale,
        agent.locale
      );
      if (
        ticket &&
        agent &&
        inInternalNote &&
        internalNoteContent !== "" &&
        internalNoteContent.indexOf(GLOBALIZE_TRANSLATE_TAG) !== -1 &&
        !isSameLanguage(customerLocale, agent.locale)
      ) {
        agentManager.translateAgentComment(
          ticket,
          sanitizeLocale(agent.locale),
          customerLocale,
          internalNoteContent
        );
      }
    });

    zafClient.on("ticket.submit.done", function(event) {
      logManager.log("ticket.submit.done", event);
    });
  }
}

const manager = new ZafManager();

export default manager;
