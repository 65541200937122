import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import analyticsManager from "./managers/analytics";
import {honeybadger} from "./api/honeybadger";
import ErrorBoundary from "@honeybadger-io/react";
import {zafClient} from "./api/zaf";
import {functions} from "./api/firebase";
import config from "./config";

try {
  zafClient.invoke("resize", {height: "130px"});
} catch (e) {
  analyticsManager.logEvent("homepage.redirected");
  window.location = config.site.url;
}

if (process.env.NODE_ENV === "development") {
  functions.useFunctionsEmulator("http://localhost:5001");
}

const rootElement = document.getElementById("root");

ReactDOM.render(
  <ErrorBoundary honeybadger={honeybadger}>
    <App/>
  </ErrorBoundary>,
  rootElement
);

serviceWorker.unregister();
