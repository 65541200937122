import {GLOBALIZE_TRANSLATE_TAG, ZENDESK_MESSAGE_SEPARATOR} from "../utils/constants";
import {zafClient} from "../api/zaf";
import logManager from "./log";

class TicketManager {
  stripHtml(content) {
    return content.replace(/<(?:.|\n)*?>/gm, "");
  }

  getFirstCommentContent(ticket) {
    if (!ticket) return null;
    if (!ticket.comments) return null;
    if (ticket.comments.length === 0) return null;
    const firstComment = ticket.comments[ticket.comments.length - 1];
    if (!firstComment) return null;
    const value = firstComment.value;
    if (!value || value === "") return null;
    return this.sanitizeCustomerComment(value);
  }

  getLastCommentContent(ticket) {
    if (!ticket) return null;
    if (!ticket.comments) return null;
    if (ticket.comments.length === 0) return null;
    const lastComment = ticket.comments[0];
    if (!lastComment) return null;
    const value = lastComment.value;
    if (!value || value === "") return null;
    return value;
  }

  isCustomerComment(comment) {
    return comment.author.role === "end-user";
  }

  sanitizeCustomerComment(content) {
    return this.stripHtml(content.split(ZENDESK_MESSAGE_SEPARATOR)[0]);
  }

  isTranslatableAgentComment(content) {
    return content.indexOf(GLOBALIZE_TRANSLATE_TAG) !== -1;
  }

  sanitizeAgentComment(content) {
    return this.stripHtml(content.replace(GLOBALIZE_TRANSLATE_TAG, "").trim());
  }

  getLatestCustomerComments(comments) {
    var customerComments = [];
    for (let i = 0; i < comments.length; i++) {
      const comment = comments[i];
      if (this.isCustomerComment(comment)) {
        customerComments.push(comment);
      } else {
        return customerComments;
      }
    }
    return customerComments;
  }

  addComment(ticket, comment, is_public) {
    return new Promise(function (resolve, reject) {
      zafClient
        .request({
          url: `/api/v2/tickets/${ticket.id}.json`,
          type: "PUT",
          contentType: "application/json",
          data: JSON.stringify({
            ticket: {
              comment: {
                body: comment,
                public: is_public
              }
            }
          })
        })
        .then(resp => {
          logManager.log("Comment has been added", resp);
          resolve(resp);
        })
        .catch(err => {
          logManager.log("Failed to add comment", err);
          reject(err);
        });
    });
  }
}

const manager = new TicketManager();

export default manager;
