import {honeybadger} from "../api/honeybadger";

class LogManager {
  isDefined(obj) {
    return typeof obj !== undefined;
  }

  error(message, error) {
    this.log(message, error);
    honeybadger.notify(message, {context: error});
  }

  log(message) {
    const params = [];
    for (let i = 1; i < arguments.length; i++) {
      if (this.isDefined(arguments[i])) params.push(arguments[i]);
    }
    const t = new Date().getTime();
    if (params.length > 0) {
      console.debug(`${t} Globalize: ${message}`, params);
    } else {
      console.debug(`${t} Globalize: ${message}`);
    }
  }
}

const manager = new LogManager();

export default manager;
