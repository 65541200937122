export default {
  site: {
    url: "https://globalize.io"
  },
  firebaseConfig: {
    apiKey: "AIzaSyB_jfdQmJkVmewQ5lWWDTkrm_zjdFaRw2I",
    authDomain: "globalize-b3492.firebaseapp.com",
    databaseURL: "https://globalize-b3492.firebaseio.com",
    projectId: "globalize-b3492",
    storageBucket: "globalize-b3492.appspot.com",
    messagingSenderId: "871781011896",
    appId: "1:871781011896:web:74c2f2a56a3c888027bf4d",
    measurementId: "G-W7PY9VQ3RD"
  },
  honeybadgerConfig: {
    api_key: '42b8f508',
    environment: 'production'
  }
};