const pack = require('../../package.json');

class AppManager {
  getVersion() {
    return pack.version;
  }
}

const manager = new AppManager();

export default manager;
