import React, {useEffect, useState} from "react";
import spinner from "./assets/spinner.gif";
import {detectLanguage} from "./api/firebase";
import agentManager from "./managers/agent";
import analyticsManager from "./managers/analytics";
import customerManager from "./managers/customer";
import logManager from "./managers/log";
import ticketManager from "./managers/ticket";
import zafManager from "./managers/zaf";
import {GLOBALIZE_TRANSLATE_TAG} from "./utils/constants";
import {isSameLanguage, sanitizeLocale} from "./utils/language";
import {Centered, Comment, Container, Icon, Status, TranslateTag} from "./components/layout";
import Footer from "./components/Footer";
import SameLanguage from "./components/SameLanguage";

const App = () => {
  const [status, setStatus] = useState("Initializing...");
  const [agent, setAgent] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [agentLocale, setAgentLocale] = useState(null);
  const [customerLocale, setCustomerLocale] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setStatus("Initializing agent...");

    zafManager
      .getUser()
      .then(user => {
        setAgent(user);
        setAgentLocale(sanitizeLocale(user.locale) || "en");
      })
      .catch(err => {
        setError(err);
      });
  }, []);

  useEffect(() => {
    if (!agent) {
      return;
    }

    setStatus("Initializing ticket details...");

    zafManager
      .getTicket()
      .then(currentTicket => {
        setTicket(currentTicket);
      })
      .catch(err => {
        setError(err);
      });
  }, [agent]);

  useEffect(() => {
    if (!agent || !ticket) {
      return;
    }

    setStatus("Determining customer language...");

    const content = ticketManager.getFirstCommentContent(ticket);

    if (!content) {
      logManager.log("Ticket does not contain any comments", {});
      setStatus("Ready");
      return;
    }

    logManager.log("Detecting customer language for content", content);

    detectLanguage({
      text: content
    })
      .then(result => {
        const locale = result.data.locale;

        analyticsManager.logEvent("customer.language.detected", {
          agent_locale: agentLocale,
          customer_locale: locale,
          via: result.data.via
        });

        console.log("locale", locale);
        setCustomerLocale(locale);
      })
      .catch(error => {
        logManager.error("Failed to detect language", error);
        setError(error);
      });
  }, [agent, ticket]);


  const translateCustomerComments = (customerComments) => {
    setStatus("Translating customer comments...");

    analyticsManager.logEvent("customer.comments.identified", {
      customer_locale: customerLocale,
      agent_locale: agentLocale,
      count: customerComments.length
    });

    customerManager
      .translateCustomerComments(
        ticket,
        agentLocale,
        customerLocale,
        customerComments
      )
      .then(res => {
        setStatus("Ready");
      })
      .catch(error => {
        logManager.error("Failed to translate customer comment", error);
        setError(error);
      });
  };

  const translateAgentComment = (lastAgentCommentContent) => {
    logManager.log("The last agent comment needs translation", lastAgentCommentContent);

    setStatus("Translating agent comment...");

    agentManager
      .translateAgentComment(
        ticket,
        agentLocale,
        customerLocale,
        lastAgentCommentContent
      )
      .then(res => {
        setStatus("Ready");
      })
      .catch(error => {
        logManager.error("Failed to translate agent comment", error);
        setError(error);
      });

  };

  useEffect(() => {
    if (!ticket || !agentLocale || !customerLocale) {
      return;
    }

    if (isSameLanguage(customerLocale, agentLocale)) {
      logManager.log("Customer language is the same as agent language, nothing to do.")
      setStatus("Ready");
      return;
    }

    setStatus("Analyzing private comments...");

    const customerComments = ticketManager.getLatestCustomerComments(
      ticket.comments
    );

    if (customerComments.length > 0) {
      translateCustomerComments(customerComments);
      return;
    } else {
      logManager.log("Customer has no comments needing translations");
    }

    setStatus("Analyzing agent comments...");

    const lastCommentContent = ticketManager.getLastCommentContent(ticket);

    if (lastCommentContent && ticketManager.isTranslatableAgentComment(lastCommentContent)) {
      translateAgentComment(lastCommentContent);
      return;
    } else {
      logManager.log("Did not find any agent comments needing translations");
    }

    setStatus("Ready");

    zafManager.initZafEvents(ticket, agent, customerLocale);

  }, [customerLocale, agentLocale, ticket]);

  if (status !== "Ready") {
    return (
      <Container style={{textAlign: "center"}}>
        <Centered>
          <Icon src={spinner} alt="Spinner"/>
          <Status>{status}</Status>
        </Centered>
      </Container>
    );
  }

  if (isSameLanguage(customerLocale, agentLocale)) {
    return (
      <SameLanguage customerLocale={customerLocale} agentLocale={agentLocale} />
    );
  }

  return (
    <Container id={ticket?.id}>
      <div>
        Add <strong>{GLOBALIZE_TRANSLATE_TAG}</strong> to an internal note to
        translate and post your response in the user's native language:
      </div>
      <Comment>
        <TranslateTag>{GLOBALIZE_TRANSLATE_TAG}</TranslateTag>
        <div>Your response...</div>
      </Comment>
      <Footer customerLocale={customerLocale} agentLocale={agentLocale} />
    </Container>
  );
};

export default App;
