import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  font-size: 12px;
  color: #869096;
`;

export const Centered = styled.div`
  padding-top: 10px;
  text-align: center;
`;

export const Status = styled.div`
  color: #666;
  font-size: 12px;
  margin: 15px;
`;

export const Comment = styled.div`
  margin-top: 15px;
  background-color: #fff6d9;
  padding: 10px;
  border-radius: 5px;
`;

export const TranslateTag = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: bold;
`;

export const Icon = styled.img`
  width: 50px;
  text-align: center;
`;
