import React from "react";
import styled from "styled-components";
import appManager from "../managers/app";
import analyticsManager from "../managers/analytics";

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  text-align: left;
  font-size: 10px;
  width: 100%;
  //display: none;
`;

export const Link = styled.a`
  text-decoration: none;
  color: #869096;
`;

export const Version = styled.div`
  float: right;
`;

export const Locale = styled.div`
  display: inline-block;
  padding-right: 5px;
`;

const Footer = (props) => {
  const { customerLocale, agentLocale } = props;

  const navigateToHomepage = () => {
    analyticsManager.logEvent("homepage.clicked");
  };

  return (
    <Container>
      <Version>
        <Link
          href="https://globalize.io"
          target="_new"
          onClick={navigateToHomepage}
        >
          globalize.io
        </Link>
        &nbsp;
        v{appManager.getVersion()}
      </Version>
      {
        agentLocale && (
          <Locale>agent: {agentLocale}</Locale>
        )
      }
      {
        customerLocale && (
          <Locale>customer: {customerLocale}</Locale>
        )
      }
    </Container>
  );
};

export default Footer;
