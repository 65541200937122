import React from "react";
import {Centered, Container, Icon, Status} from "./layout";
import checkmark from "../assets/checkmark.png";
import Footer from "./Footer";

const SameLanguage = (props) => {
  const { customerLocale, agentLocale } = props;

  return (
    <Container style={{textAlign: "center"}}>
      <Centered>
        <Icon src={checkmark} alt="Checkmark"/>
        <Status>
          Your customer speaks the same language as you and no translations
          are needed.
        </Status>
      </Centered>
      <Footer customerLocale={customerLocale} agentLocale={agentLocale} />
    </Container>
  );
};

export default SameLanguage;
